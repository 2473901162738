const sliderDelay = 3000
const speed = 1000
console.log('app.js load')

// slider 插件 init
function swiperInit() {
  let isStart = false
  let timer = null
  if (timer === null) {
    timer = setInterval(() => {
      if (Swiper && !isStart) {
        clearInterval(timer)
        isStart = true
        var swiper = new Swiper('.swiper-container', {
          pagination: {
            el: '.swiper-pagination',
          },
          speed: speed,
          autoplay: {
            delay: sliderDelay,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          // 監聽切換事件
          on: {
            init: function() {
              // 初始完成時分頁按鈕尚未render完畢，靜待500毫秒後才新增svg按鈕
              setTimeout(() => {
                renderSvgBtn()
              }, 500)
            },
            slideChangeTransitionStart: function() {
              const btnDOM = document.querySelectorAll('.swiper-pagination-bullet')[this.activeIndex]
              circlePadthAnimStart(btnDOM)
            },
          },
        })
      }
    }, 500)
  }
}
// window.addEventListener('load', () => {

// })

// svg 長度抓取

function circlePadthAnimStart(el, first) {
  let reducdTime = first ? -1500 : 0
  circlePadthAnimStop()
  const svgPath = el.querySelector('.u-circle-path')
  const length = svgPath.getTotalLength()
  TweenMax.set(svgPath, {
    strokeDashoffset: 0,
  })

  TweenMax.to(svgPath, (sliderDelay + speed + reducdTime) / 1000, {
    strokeDashoffset: length,
  })
}

function circlePadthAnimStop() {
  TweenMax.set('.u-circle-path', {
    strokeDashoffset: 119,
  })
}

// 新增svg按鈕
function renderSvgBtn() {
  const btns = document.querySelectorAll('.swiper-pagination-bullet')
  if (btns) {
    btns.forEach(item => {
      const svgBox = document.createElement('div')
      svgBox.classList.add('u-svg-btn-box')
      svgBox.innerHTML = `
        <svg
        class="u-svg-circle"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 39.9 39.7"
        width="37"
        height="37"
      >
        <path
          d="M19.9.8c9.5 0 18.9 7.8 18.9 19 0 13.1-11.6 19-18.9 19-8.3 0-19.1-6.9-19.1-19C.8 7.1 11.7.8 19.9.8"
          fill="none"
          stroke="#000"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          class="u-circle-path"
          style="stroke-dashoffset: 0px; stroke-dasharray: 119px;"
        />
      </svg>`
      item.appendChild(svgBox)
    })
    const btnDOM = document.querySelectorAll('.swiper-pagination-bullet')[0]
    circlePadthAnimStart(btnDOM, true)
  }
}

// navbar 手機版toggle按鈕
const navbarToggle = document.getElementsByClassName('u-navbar-mobile__toggle')[0]
const navbarMobile = document.getElementsByClassName('u-navbar-mobile-box')[0]
navbarToggle.addEventListener('click', el => {
  if (el.target.classList.contains('u-navbar-mobile__toggle')) {
    el.target.classList.toggle('active')
  } else {
    console.log(el.target.closest('.u-navbar-mobile__toggle'))
    el.target.closest('.u-navbar-mobile__toggle').classList.toggle('active')
  }
  navbarMobile.classList.toggle('active')
})

// 如果是商品內頁，禁止超連結
if (window.location.pathname.split('/')[1] === 'product') {
  let sliderLinks = document.querySelectorAll('.swiper-slide a')
  sliderLinks = [...sliderLinks]
  sliderLinks.forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault()
    })
  })
}
