function renderCourseList() {
  // dom target
  const renderTarget = document.getElementById('u-main-content')
  renderTarget.innerHTML = ''

  // getJSON
  // https://richyourmind.nasrio.net/json/course-list.json
  // const jsonURL = 'https://richyourmind.nasrio.net/json/course-list.json?201903131112'
  // const jsonURL = './json/course-list.json'
  // const jsonURL = '/api/course_banner.php'

  let ROOT_URL = null
  if (location.host === 'localhost') {
    ROOT_URL = '/'
  } else {
    ROOT_URL = `https://richyourmind.nasrio.net/`
  }
  const bannerAPI = `${ROOT_URL}api/course_banner.php`
  const courseListAPI = `${ROOT_URL}api/course_list.php`
  const goodsListAPI = `${ROOT_URL}api/goods_list.php`

  // render 順序 banner 跟 course 同步
  // 商品則等課程渲染完後才接著渲染
  renderBanner()
  renderCourse(renderGoods)

  function renderCourse(callback) {
    // render 精選活動
    fetch(courseListAPI)
      .then(res => {
        return res.json()
      })
      .then(data => {
        // 依照theorder號碼排序 大 > 小
        const dataSort = [...data.course]
        dataSort.sort((a, b) => {
          return b.theorder - a.theorder
        })

        // 課程標題
        const courseTitle = document.createElement('h2')
        courseTitle.classList.add('u-course__title')
        courseTitle.innerText = '精選活動'
        renderTarget.appendChild(courseTitle)

        const list = document.createElement('ul')
        list.classList.add('u-course__list')
        dataSort.forEach(item => {
          list.innerHTML += `
          <li class="u-course__list__item">
            <a href="?courseId=${item.id}">
              <div class="u-course__list__item__img" style="background-image: url('${ROOT_URL}${item.image}')"></div>
            </a>
            <div class="u-course__list__item__title">${item.title}</div>
            <div class="u-course__list__item__name">${item.teacher}</div>
          </li>
        `
        })
        renderTarget.appendChild(list)
        if (callback) callback()
      })
      .catch(err => console.log(err))
  }

  // render goods 周邊商品
  function renderGoods(callback) {
    fetch(goodsListAPI)
      .then(res => res.json())
      .then(data => {
        if (data.length !== 0 || data.goods.length > 0) {
          const goodsListData = [...data.goods]
          goodsListData.sort((a, b) => {
            return b.theorder - a.theorder
          })
          // 分隔線、周邊商品標題
          const goodsLine = document.createElement('div')
          goodsLine.classList.add('u-course__line')
          renderTarget.appendChild(goodsLine)
          const goodsTitle = document.createElement('h2')
          goodsTitle.classList.add('u-course__title')
          goodsTitle.innerText = '周邊商品'
          renderTarget.appendChild(goodsTitle)
          // list
          const goodsList = document.createElement('ul')
          goodsList.classList.add('u-course__list')

          goodsListData.forEach(item => {
            goodsList.innerHTML += `
            <li class="u-course__list__item">
              <a href="${item.store_link}">
                <div class="u-course__list__item__img" style="background-image: url('${ROOT_URL}${item.image}')"></div>
              </a>
              <div class="u-course__list__item__title">${item.title}</div>
            </li>
          `
          })

          renderTarget.appendChild(goodsList)
          if (callback) callback()
        }
      })
      .catch(err => console.log(err))
  }

  // render banner
  function renderBanner(callback) {
    fetch(bannerAPI)
      .then(res => {
        return res.json()
      })
      .then(data => {
        // 排序控制
        data.course_banner.sort(function(a, b) {
          // return b.theorder - a.theorder //從大到小
          return a.theorder - b.sotheorderrt //從小到大
        })

        const bannerTarget = document.getElementById('swiper-banner')
        bannerTarget.innerHTML = ''
        if (data.course_banner.length !== 0) {
          let bannerHtml = `
              ${data.course_banner
                .map(
                  item => `
                    <div class="swiper-slide">
                      <a
                        href="${item.link}"
                      >
                        <div
                          class="u-banner"
                          style="background-image: url(${ROOT_URL}${item.image})"
                        >
                          <div class="u-banner__content">
                            <div class="u-banner__content__title">${item.title}</div>
                            <div class="u-banner__content__subtitle">
                              ${item.subtitle}
                            </div>
                            <div class="u-banner__content__text">
                              ${item.text}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  `
                )
                .join('')}
              `
          bannerTarget.innerHTML = bannerHtml
          swiperInit()
          if (callback) callback()
        }
      })
      .catch(err => console.log(err))
  }
}
