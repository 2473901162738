function renderCourseDetail(id) {
  // dom target
  const renderTarget = document.getElementById('u-main-content')
  renderTarget.innerHTML = ''
  // getJSON

  // let jsonURL = null
  // if (location.host === 'localhost') {
  //   jsonURL = `./json/course-detail-${id}.json`
  // } else {
  //   jsonURL = `https://richyourmind.nasrio.net/json/course-detail-${id}.json?201903131112`
  // }


  let ROOT_URL = null
  if (location.host === 'localhost') {
    ROOT_URL = '/'
  } else {
    ROOT_URL = `https://richyourmind.nasrio.net/`
  }
  renderNew(ROOT_URL)
  // renderOld()

  // 新的API
  function renderNew(rootUrl) {
    const courseId = location.search.split('?courseId=')[1]
    console.log('courseId ', courseId)
    if (!courseId) {
      // location.href = '/'
    } else {
      const courseAPI = `${rootUrl}api/course_detail.php?id=${courseId}`
      fetch(courseAPI)
        .then(res => res.json())
        .then(data => {
          console.log('courseDetail', data.course)

          // 左側內容 render
          const side = document.createElement('div')
          side.classList.add('u-main__left', 'u-hidden-lg')
          side.innerHTML = data.course.content_side
          renderTarget.appendChild(side)

          // 右側內容 main
          const main = document.createElement('div')
          main.classList.add('u-main__right')

          // render youtuber
          const youtube = data.course.youtube
          if (youtube) {
            let youtubeHTML = ''
            youtubeHTML = `<div class="u-youtube"><iframe width='100%' height='100%' src='${youtube}' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>`
            main.innerHTML += youtubeHTML
          }

          // render main 課程內容
          // 將 /upload/ 路徑替換
          main.innerHTML += data.course.content_primary.replace('"/upload/', `"${ROOT_URL}upload/`);

          // render 手機版 side desc (teacher)
          const teacher = data.course.content_side
          let teacherMobileHtml = `<div class="u-teacher u-hidden u-flex-lg">
                ${teacher}
              </div>`
          main.innerHTML += teacherMobileHtml

          // 課程時間區塊
          if (data.course.times.length > 0) {
            // 依照時間 小 > 大 排序
            data.course.times.sort(function(a, b) {
              return new Date(a.date).getTime() - new Date(b.date).getTime()
            })

            let dateHeadHTML = `
                <div class="u-course__date">
                <div class="u-course__date-title">課程時間</div>
                <ul class="u-course__date-lists">
                  <li class="u-course__date-list u-course__date-list--head">
                    <div class="u-date-title-date">日期</div>
                    <div class="u-date-title-addr">地點</div>
                    <div class="u-date-title-time">時間</div>
                    <div class="u-date-title-name">課程名稱</div>
                    <div class="u-date-tool">點按報名</div>
                    <div class="u-date-teacher">授課講師</div>
                  </li>
                  ${data.course.times
                    .map(
                      item => `
                        <li class="u-course__date-list">
                          <div class="u-date-title-date">
                            <span>${item.show_date}</span>
                            <span class="u-inline-time u-hidden u-display-md">${item.time}</span>
                          </div>
                          <div class="u-date-title-addr">${item.addr}</div>
                          <div class="u-date-title-time">${item.time}</div>
                          <div class="u-date-title-name">
                          <span>${item.name}</span>
                          <span class="u-inline-teacher u-hidden u-display-md">${item.teacher}</span>
                          </div>
                          <a href="${item.link}" target="_blank" class="u-date-tool u-date-tool-btn">立即報名</a>
                          <div class="u-date-teacher">${item.teacher}</div>
                        </li>
                      `
                    )
                    .join('')}
                </ul>
              </div>
                `
            main.innerHTML += dateHeadHTML
          }

          // 底部QA區塊
          if (data.course.content_qa) {
            let qaHTML = `
                <div class="u-course__qa">
                  <div class="u-course__qa__title">FAQS</div>
                  ${data.course.content_qa}
                </div>
              `
            main.innerHTML += qaHTML
          }
          renderTarget.appendChild(main)

          // 更新 banner
          const bannerTarget = document.getElementById('swiper-banner')
          bannerTarget.innerHTML = ''
          if (data.course.images.length > 0) {
            // console.alert(data.courseDetail.banner.length)
            let bannerHtml = `
              ${data.course.images
                .map(
                  item => `
                    <div class="swiper-slide">
                      <a
                        href="${data.course.store_link}"
                      >
                        <div
                          class="u-banner"
                          style="background-image: url(${ROOT_URL}${item})"
                        >
                          <div class="u-banner__content">
                            <div class="u-banner__content__title">${data.course.title}</div>
                            <div class="u-banner__content__subtitle">
                              ${data.course.subtitle}
                            </div>
                            <div class="u-banner__content__text">
                              ${data.course.banner_desc}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  `
                )
                .join('')}
              `
            bannerTarget.innerHTML = bannerHtml
            swiperInit()
          }

          // 更新手機版banner內文
          const bannerTargetModile = document.getElementById('banner-text-mobile')
          bannerTargetModile.innerHTML = `${data.course.banner_desc}`
        })
        .catch(err => console.log(err))
    }
  }

  function renderOld() {
    fetch(jsonURL)
      .then(res => {
        return res.json()
      })
      .then(data => {
        // 左側內容 render
        const side = document.createElement('div')
        side.classList.add('u-main__left', 'u-hidden-lg')
        side.innerHTML = data.courseDetail.courseDesc.side
        renderTarget.appendChild(side)

        // 右側內容 main
        const main = document.createElement('div')
        main.classList.add('u-main__right')

        // render youtuber
        const youtube = data.courseDetail.youtube
        if (youtube) {
          let youtubeHTML = ''
          youtubeHTML = `<div class="u-youtube">${youtube}</div>`
          main.innerHTML += youtubeHTML
        }

        // render main 課程內容
        const mainPrimary = data.courseDetail.courseDesc.mainPrimary
        let mainPrimaryHtml = `
        ${mainPrimary
          .map(
            item => `
          <ul class="u-list">
            <div class="u-list__title">${item.title}</div>
            <li class="u-list__item">${item.text}</li>
          </ul>
          `
          )
          .join('')}
        `
        main.innerHTML += mainPrimaryHtml

        // render main 課程費用資訊
        const mainDesc = data.courseDetail.courseDesc.mainFee
        let mainDescHtml = `
        <div class="u-course__info">
          <div class="u-course__info__title">${mainDesc.title}</div>
          ${mainDesc.list
            .map(
              item => `
            <ul class="u-course__info__list">
              <li class="u-course__info__list__item">${item.title}</li>
              <li class="u-course__info__list__item">${item.desc}</li>
            </ul>
          `
            )
            .join('')}
        </div>
      `

        main.innerHTML += mainDescHtml

        // render 手機版 side desc
        // render 手機版 teacher
        const teacher = data.courseDetail.courseDesc.side
        let teacherMobileHtml = `<div class="u-teacher u-hidden u-flex-lg">
          ${teacher}
        </div>`

        main.innerHTML += teacherMobileHtml

        // 自定義html區塊
        let mainHTML = `
        <div class="u-course__html">${data.courseDetail.html}</div>
      `
        main.innerHTML += mainHTML

        // 課程時間區塊
        if (data.courseDetail.courseTime !== 0) {
          const val = data.courseDetail.courseTime
          let dateHeadHTML = `
        <div class="u-course__date">
        <div class="u-course__date-title">課程時間</div>
        <ul class="u-course__date-lists">
          <li class="u-course__date-list u-course__date-list--head">
            <div class="u-date-title-date">日期</div>
            <div class="u-date-title-addr">地點</div>
            <div class="u-date-title-time">時間</div>
            <div class="u-date-title-name">課程名稱</div>
            <div class="u-date-tool">點按報名</div>
            <div class="u-date-teacher">授課講師</div>
          </li>
          ${val.list
            .map(
              item => `
                <li class="u-course__date-list">
                  <div class="u-date-title-date">
                    <span>${item.date}</span>
                    <span class="u-inline-time u-hidden u-display-md">${item.time}</span>
                  </div>
                  <div class="u-date-title-addr">${item.addr}</div>
                  <div class="u-date-title-time">${item.time}</div>
                  <div class="u-date-title-name">
                  <span>${item.name}</span>
                  <span class="u-inline-teacher u-hidden u-display-md">${item.teacher}</span>
                  </div>
                  <a href="${item.link}" target="_blank" class="u-date-tool u-date-tool-btn">立即報名</a>
                  <div class="u-date-teacher">${item.teacher}</div>
                </li>
              `
            )
            .join('')}
        </ul>
      </div>
        `
          main.innerHTML += dateHeadHTML
        }

        renderTarget.appendChild(main)

        // // 繳費重要訊息
        // let mainHTML2 = `
        //   ${data.courseDetail.html2}
        // `;
        // main.innerHTML += mainHTML2;

        // 底部QA區塊
        if (data.courseDetail.htmlQA) {
          let qaHTML = `
          <div class="u-course__qa">
            <div class="u-course__qa__title">FAQS</div>
            ${data.courseDetail.htmlQA}
          </div>
        `
          main.innerHTML += qaHTML
        }

        // 更新banner
        const bannerTarget = document.getElementById('swiper-banner')
        bannerTarget.innerHTML = ''
        if (data.courseDetail.banner.length !== 0) {
          // console.alert(data.courseDetail.banner.length)
          let bannerHtml = `
          ${data.courseDetail.banner
            .map(
              item => `
                <div class="swiper-slide">
                  <a
                    href="https://www.richyourmind.com/product/product&product_id=${data.courseDetail.id}"
                  >
                    <div
                      class="u-banner"
                      style="background-image: url(${item.image})"
                    >
                      <div class="u-banner__content">
                        <div class="u-banner__content__title">${item.title}</div>
                        <div class="u-banner__content__subtitle">
                          ${item.subtitle}
                        </div>
                        <div class="u-banner__content__text">
                          ${item.desc}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              `
            )
            .join('')}
          `
          bannerTarget.innerHTML = bannerHtml
          swiperInit()
        }

        // 更新手機版banner內文
        const bannerTargetModile = document.getElementById('banner-text-mobile')
        bannerTargetModile.innerHTML = ''
        bannerTargetModile.innerHTML = `
        ${data.courseDetail.banner_mobile}
      `
      })
  }
}
